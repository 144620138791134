import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { createNetworkStatusNotifier } from 'react-apollo-network-status'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { ROUTE_NAMES } from '#layouts/Unauthorized/interfaces'
import { routes } from '#layouts/Unauthorized/routes'
import { authorizedClient, unauthorizedClient } from '#lib/apolloClient'
import {
  ConfigureMfaDocument,
  IConfigureMfaPayload,
  LoginDocument,
  LogoutDocument,
  ReauthorizeDocument,
} from '#lib/graphql'
import { getSSOConfig, storageAccessTokenKey } from '#lib/jwtHelper'
import { TLocale } from '#lib/sharedInterfaces'

import { IAuthContext, IProps } from './interfaces'

export const AuthContext = React.createContext<IAuthContext | null>(null)

export const useAuthProvider = (): IAuthContext => {
  const context = useContext(AuthContext)

  if (!context) throw new Error('AuthContext should only be used within AuthProvider')

  return context
}

export const AuthProvider: React.FC<IProps> = ({ children, isLoggedIn }): JSX.Element => {
  const navigate = useNavigate()
  const [loggedIn, setLoggedIn] = useState<boolean>(isLoggedIn)
  const { i18n } = useTranslation()
  const locale = i18n.language as TLocale

  // This needs to be here to make sure no old tokens remain in storage while switching to the new storage keys
  useEffect(() => {
    localStorage.removeItem('jwtToken')
    sessionStorage.removeItem('jwtToken')

    localStorage.removeItem('refreshToken')
    sessionStorage.removeItem('refreshToken')
  }, [])

  const clearTokensFromStorage = (): void => {
    localStorage.removeItem(storageAccessTokenKey)
    sessionStorage.removeItem(storageAccessTokenKey)
  }

  const clearCurrentUser = useCallback((): void => {
    clearTokensFromStorage()
    setLoggedIn(false)
  }, [])

  const storeTokens = useCallback((storage: Storage, token: string) => {
    storage.setItem(storageAccessTokenKey, token)
  }, [])

  const handleLogoutFinally = useCallback(() => {
    clearCurrentUser()
  }, [clearCurrentUser])

  const handleLoginSuccess = useCallback(
    (tokens: { accessToken: string }, remember?: boolean): void => {
      const redirectPath = sessionStorage.getItem('redirectPath')
      const storage = remember ? localStorage : sessionStorage

      storeTokens(storage, tokens.accessToken)
      setLoggedIn(true)

      window.history.replaceState({}, '')

      if (redirectPath) {
        navigate(redirectPath)
        sessionStorage.removeItem('redirectPath')
        return
      }

      navigate(routes[ROUTE_NAMES.LOGIN])
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const login = useCallback(
    (
      email?: string,
      password?: string,
      remember?: boolean,
      otpToken?: string,
      redirectPath?: string
    ): Promise<boolean | void> => {
      clearCurrentUser()

      if (redirectPath) sessionStorage.setItem('redirectPath', redirectPath)

      const client = unauthorizedClient(locale)
      const variables = { email: email || '', password: password || '', otpToken }

      return client
        .mutate({ mutation: LoginDocument, variables })
        .then(({ data }) => {
          const { result, otpRequired, hasOtpConfigured, passwordResetRequired, tokens } = data.login

          if (tokens.accessToken) handleLoginSuccess(tokens, remember)
          else if (otpRequired) {
            const routeParams = { email, password, remember }

            if (hasOtpConfigured) navigate(routes[ROUTE_NAMES.MFA_LOGIN], { state: routeParams })
            else navigate(routes[ROUTE_NAMES.MFA_CONFIGURE], { state: routeParams })
          } else if (passwordResetRequired) {
            const resetPasswordToken = tokens?.resetToken
            const routeParams = { forcePasswordReset: true }

            navigate(
              { pathname: routes[ROUTE_NAMES.RESET_PASSWORD], search: `?token=${resetPasswordToken}` },
              { state: routeParams }
            )
          }

          return !result.error
        })
        .catch((err) => console.error('Login mutation returned error:', err.message))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clearCurrentUser, handleLoginSuccess, locale]
  )

  const handleConfigureMfaSuccess = useCallback(
    (email: string, password: string, remember?: boolean, otpToken?: string) => {
      login(email, password, remember, otpToken).catch(() => {})
    },
    [login]
  )

  const configureMFA = useCallback(
    async (email: string, password: string, remember?: boolean, otpToken?: string): Promise<IConfigureMfaPayload> => {
      const client = unauthorizedClient(locale)
      const input = { email, password, validateOtpSecret: otpToken || null }

      return client
        .mutate({ mutation: ConfigureMfaDocument, variables: { input } })
        .then(({ data }) => {
          if (data.configureMfa.configurationSuccess) handleConfigureMfaSuccess(email, password, remember, otpToken)

          return data.configureMfa
        })
        .catch((err) => console.error('ConfigureMFA mutation returned error:', err.message))
    },
    [handleConfigureMfaSuccess, locale]
  )

  const handleReauthorizeFailed = useCallback((): Promise<void> => {
    // The user could be an SSO user, if so, set the host url (sso login url) here.
    // We set the url here already because the logout function clears the storageAccessTokenKey which is needed to retrieve this url.
    const client = unauthorizedClient(locale)
    const ssoConfig = getSSOConfig()
    const ssoHostUrl = ssoConfig?.host

    return new Promise((resolve, reject) => {
      client
        .mutate({ mutation: LogoutDocument })
        .finally(() => {
          const navigateOptions = {
            pathname: routes[ROUTE_NAMES.SESSION_EXPIRED],
            ...(!!ssoHostUrl && { search: `?url=${ssoHostUrl}` }),
          }

          handleLogoutFinally()
          navigate(navigateOptions)
          resolve()
        })
        .catch(reject)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleLogoutFinally])

  const reauthorize = useCallback(
    (token?: string): Promise<string> => {
      return new Promise((resolve, reject) => {
        const client = unauthorizedClient(locale, handleReauthorizeFailed)

        client
          .query({ query: ReauthorizeDocument, variables: { refreshToken: token } })
          .then(({ data }) => {
            const storage = sessionStorage.getItem(storageAccessTokenKey) ? sessionStorage : localStorage

            storeTokens(storage, data.reauthorize.accessToken)
            resolve(data.reauthorize.accessToken)
            setLoggedIn(true)
          })
          .catch(reject)
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale]
  )

  const reauthorizeWithRefreshToken = useCallback(reauthorize, [reauthorize])

  const logout = useCallback((): Promise<void> => {
    const { link } = createNetworkStatusNotifier()
    const client = authorizedClient(link, locale, reauthorizeWithRefreshToken)

    return new Promise((resolve, reject) => {
      client
        .mutate({ mutation: LogoutDocument })
        .finally(() => {
          handleLogoutFinally()
          resolve()
        })
        .catch(reject)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reauthorizeWithRefreshToken, clearCurrentUser, handleLogoutFinally, locale])

  const reauthorizeWithToken = useCallback(
    (token: string): Promise<string> => {
      return new Promise((resolve) => {
        logout()
          .finally(() => {
            reauthorize(token)
              .then(resolve)
              .catch(() => {})
          })
          .catch(() => {})
      })
    },
    [logout, reauthorize]
  )

  const options = useMemo(() => {
    return {
      login,
      configureMFA,
      logout,
      loggedIn,
      reauthorizeWithToken,
      reauthorizeWithRefreshToken,
    }
  }, [login, configureMFA, logout, loggedIn, reauthorizeWithToken, reauthorizeWithRefreshToken])

  return <AuthContext.Provider value={options}>{children}</AuthContext.Provider>
}
